import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FohmoduleSelectorService } from '@foh/shared/services/fohmodule-selector.service';

@Injectable({
  providedIn: 'root'
})
export class CheckcurrentmoduleroutesGuard implements CanActivate {

  constructor(
    private fohmoduleSelectorService: FohmoduleSelectorService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const allowedModules = next.data.modules;
    const currentModule = this.fohmoduleSelectorService.getCurrentModule();
    return allowedModules.includes(currentModule);
  }
}
