import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from '@foh/globals';
import { Term } from './term';
@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(private http: HttpClient,
    private globals: Globals) { }

  getAll() {
    return this.http.get<Term[]>(this.globals.WEBAPI + this.globals.URL_TERMS_LIST);
  }
  getAllWedding() {
    return this.http.get<Term[]>(this.globals.WEBAPI + this.globals.URL_TERMS_WEDDING_LIST);
  }

  get(id) {
    return this.http.get<Term>(this.globals.WEBAPI + this.globals.URL_TERMS_GET,
      {
        params: { id: id }
      });
  }
  getByCode(code) {
    return this.http.get<Term>(this.globals.WEBAPI + this.globals.URL_TERMS_GETBYCODE,
      {
        params: { code: code }
      });
  }
  searchUsedClientCategories(id) {
    return this.http.get(this.globals.WEBAPI + this.globals.URL_TERMS_SEARCHCLIENTCATS,
      {
        params: { id: id }
      });
  }

  add(record: Term) {
    return this.http.post<Term>(this.globals.WEBAPI + this.globals.URL_TERMS_CREATE, record);
  }

  update(record: Term) {
    return this.http.post<Term>(this.globals.WEBAPI + this.globals.URL_TERMS_UPDATE, record);
  }

  delete(id: number) {
    return this.http.post(this.globals.WEBAPI + this.globals.URL_TERMS_DELETE,
      { id: id }
    );
  }
}
